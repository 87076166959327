import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from "react-router-dom";
import NavBar from '../NavBar';


const items = [{ link: "/logout", label: "Ausloggen" }]

const useStyles = makeStyles((theme) => ({

  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  box: {
    color: "#0B5AA1 !important",
    marginTop: "50px",
    marginBottom: "2%",
    maxWidth: "750px",
    margin: "50px 120px",
    margin: "auto",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",


    '&:hover': {
      color: "#073F71 !important"
    },

  },

  a: {
    color: "#0B5AA1 !important",

    '&:hover': {
      color: "#073F71 !important",

    }
  }

}));



const TITLE = 'Europakongress Finanzielle Freiheit 2021'


export default function Home(props) {
  const classes = useStyles()
  let { params } = useParams();
  const [open, setOpen] = React.useState(params);


  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <NavBar items={items} burger={true}/>

      <img className={classes.titleImage} src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Titelbild+v001.jpg" />

      <Container maxWidth="md">

        <div className={classes.box}>
          <div className="card-content" style={{ padding: '28px' }}>
            <h2>Der Europakongress 2021 hilft dir auf dem Weg zum Traum der finanziellen Unabhängigkeit. </h2>
            <br></br>
            <p>Lerne von Top-Experten, wie du ein passives Einkommen aufbaust.  </p>
            <br />
            <p>In den kommenden 2 Tagen erfährst du auf 2 Bühnen mit freier Vortragsauswahl Tipps und Tricks zu den Themen: Geldsicherheit, Geld verdienen und mentaler Stärke! </p>
            <br />
            <p>Ich freue mich auf dich. </p>
            <br></br>
            <p> Herzlichst 
            <br></br>

Dein Thorsten Wittmann  </p>

           
          </div>
          <div className="video-footer card-footer">
            <Link to={"/stream"}>
            <Button
              variant="contained"
              size="large"
              color="secondary"
            >Zum Livestream </Button>
            </Link>
            
          </div>
        </div>
      </Container>


      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Am Samstag, den 24.04.2021 ist es soweit. </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Ab 08:30 Uhr gelangst du zum Livestream. </p>
            <p>Ab 09:00 Uhr beginnt das Event. </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Link to={"/logout"}>
            <Button color="primary">
              Ausloggen
          </Button>
          </Link>
          <Button onClick={handleClose} color="primary">
            Schließen
          </Button>
        </DialogActions >

      </Dialog>

    </>
  )
}

