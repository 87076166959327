import { gql, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";


const GET_3QToken = gql`
query get3QToken($project: String!){
    get3QToken(project: $project){
      key, timestamp, dataId
    }
}`




export default function Projects({project}) {  


  const { loading, error, data } = useQuery(GET_3QToken, {
    variables: { project: project},

});

    useEffect(() => {
   if(data && data.get3QToken){

    if(window.js3qVideoPlayer){
      window.js3qVideoPlayer =  new window.js3q({
        'dataid': data.get3QToken.dataId,
        'container': 'myVideoPlayer',
        'sticky': true,
        'key' : data.get3QToken.key ,
        'timestamp' : data.get3QToken.timestamp
    }); 
    }else {
      var js3qVideoPlayer =  new window.js3q({
        'dataid': data.get3QToken.dataId,
        'container': 'myVideoPlayer',
        'sticky': true,
        'key' : data.get3QToken.key ,
        'timestamp' : data.get3QToken.timestamp
    }); 
    }
     
   }   
    }, [data]);
  
  
  if (error) return `${error}`
  
  if(data && data.get3QToken){              

  }

    return (
      <>      
            <div id="myVideoPlayer"></div>
      </>
    )
  

}
