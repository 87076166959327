import React, { Fragment, useEffect } from 'react'

import tawkTo from "tawkto-react";



const tawkToPropertyId = '607d56f4f7ce1827093bc527'
const tawkToKey = '1f3kpnb55'

function TawkTo() {

  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey)
  }, [])

  return null
  
}

export default TawkTo;
