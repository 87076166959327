import React, { Fragment, useEffect } from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { Container } from '@material-ui/core';
import ApolloProvider from './ApolloProvider'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import './App.scss';

import { deDE } from '@material-ui/core/locale';
import { AuthProvider } from './context/auth'

import Stream from './pages/home/Stream'
import Stream2 from './pages/home/Stream2'
import Landing from './pages/Landing'
import Admin from './pages/Admin'
import Exhibitors from './pages/home/Exhibitors';
import ExhibitorsContainer from './pages/home/ExhibitorsContainer';
import WelcomeDe from './pages/home/Welcome';
import Logout from './pages/home/Logout.js';
import AfterEvent from './pages/home/AfterEvent';
import Footer from './components/Footer'
import DynamicRoute from './util/DynamicRoute'
import TawkTo from './components/TawkTo';

const theme = createMuiTheme({

  palette: {
    primary: {
      main: '#153357',
      light: '#fff',
    },
    secondary: {
      main: '#EE9012',
    },

  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: "15px"
    },
  }

}, deDE)



const useStyles = makeStyles((theme) => ({

container: {
  padding: '0', maxWidth: "100%", minHeight: "100vh", position: "relative", paddingBottom: "100px", display: "block",

  [theme.breakpoints.down('sm')]: {
    paddingBottom: "250px",
  },

}
  

}));

  



function App() {
  const classes = useStyles()


  useEffect(() => {
  }, [])

  return (
    <Fragment>
      <ApolloProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <BrowserRouter >
              <Container className={classes.container}>
                <Switch>
                <DynamicRoute exact path="/" component={WelcomeDe} authenticated />
                  <DynamicRoute exact path="/landing" component={Landing} guest />
                  <DynamicRoute exact path="/exhibitors" component={Exhibitors}  />
                  <DynamicRoute exact path="/exhibitors/:id" component={ExhibitorsContainer}  />
                  <DynamicRoute exact path="/stream" component={Stream} authenticated />
                  <DynamicRoute exact path="/stream2" component={Stream2} authenticated />
                  <DynamicRoute exact path="/logout" authenticated ><Logout /></DynamicRoute>
                  <DynamicRoute exact path="/fin" authenticated ><AfterEvent /></DynamicRoute>
                  <DynamicRoute path="/:params" component={WelcomeDe} authenticated />
                  <DynamicRoute path="*" authenticated><Redirect to={"/"} /></DynamicRoute>
                </Switch>
                <TawkTo />
                <Footer />
              </Container>
            </BrowserRouter>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Fragment>

  );
}

export default App;
