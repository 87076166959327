import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import LoginForm from '../components/LoginFormAdmin';
import NavBar from './NavBar';

const TITLE = 'Europakongress Finanzielle Freiheit 2021'



const useStyles = makeStyles((theme) => ({
  bullet: {
    display: 'inline-block',
    margin: '0 10px',
    transform: 'scale(2)',
  },
  title: {
    flexGrow: 2,
    color: "#0B5AA1",
    fontWeight: 600
  },

  logo: {
    maxHeight: "30px",
    alignSelf: "center",
    backgroundSize: "contain",
    marginRight: theme.spacing(4),
  },
  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  }

}));


const items = [{}]




export default function Landing(props) {
  const myRef = useRef()
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));



  useEffect(() => {


  }, []);




  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <NavBar items={items} />
      <img className={classes.titleImage} src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Titelbild+v001.jpg" />
      <div ref={myRef}><LoginForm /></div>


    </>

  )
}
