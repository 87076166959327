import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, Link, useParams } from "react-router-dom";
import { useAuthDispatch, useAuthState } from '../../context/auth';
import NavBar from '../NavBar';


const items = [{ link: "/logout", label: "Ausloggen" }]
const einlass = new Date("2021/04/12 16:15:00").getTime()

const useStyles = makeStyles((theme) => ({

  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  box: {
    color: "#0B5AA1 !important",
    marginTop: "50px",
    marginBottom: "2%",
    maxWidth: "750px",
    color: "#0B5AA1",
    margin: "50px 120px",
    margin: "auto",
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",


    '&:hover': {
      color: "#073F71 !important"
    },

  },

  a: {
    color: "#0B5AA1 !important",

    '&:hover': {
      color: "#073F71 !important",

    }
  }

}));



const TITLE = 'Europakongress Finanzielle Freiheit 2021'


export default function Home(props) {
  const classes = useStyles()
  const history = useHistory();
  let { params } = useParams();
  const [open, setOpen] = React.useState(params);


  const handleClose = () => {
    setOpen(false);
  };
  const handleClickStream = () => {
    let timestamp = Date.now()



    if (einlass > timestamp) {
      setOpen(true);
    }
    else {
      history.push("/stream");

    }
  };



  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <NavBar items={items} burger={true}/>

      <img className={classes.titleImage} src="https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Titelbild+v001.jpg" />

      <Container maxWidth="md">

        <div className={classes.box}>
          <div className="card-content" style={{ padding: '28px' }}>
            <h2 style={{textAlign: "center"}}>Danke für Ihre Teilnahme.</h2>
            <br></br>            
          </div>
          <div className="video-footer card-footer">
            <Link to={"/logout"}>
            <Button
              variant="contained"
              size="large"
              color="primary"
            >Ausloggen</Button>
            </Link>            
          </div>
        </div>
      </Container>

    </>
  )
}

