import React from 'react'
import { useAuthState } from '../context/auth'
import { Route, Redirect, useHistory } from 'react-router-dom'

export default function DynamicRoute(props) {
    const { user } = useAuthState()
  
 


  
    if (props.authenticated && !user) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/landing' }} />
    }

    // check if route is restricted by role
    if (props.authenticated && props.admin && user.role != 'admin') {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />
    }
   
    // authorised so return component
    
        return <Route component={props.component}{...props} />
    

} 
