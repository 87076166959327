  
import React, { createContext, useReducer, useContext } from 'react'

const NavigationStateContext = createContext()
const NavigationDispatchContext = createContext()

const navigationReducer = (state, action) => {

const items = [{ link: "/logout", label: "Ausloggen" }]

  switch (action.type) {
    case 'SET_NAV_DEFAULT':
      console.log(items)
      return {
        ...state,
        navList: items,
      }  
      case 'SET_NAV_LIST':
      return {
        ...state,
        navList: action.payload,
      }     
}
}
export const NavigationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(navigationReducer, { navList: null })

  return (
    <NavigationDispatchContext.Provider value={dispatch}>
      <NavigationStateContext.Provider value={state}>
        {children}
      </NavigationStateContext.Provider>
    </NavigationDispatchContext.Provider>
  )
}

export const useNavState = () => useContext(NavigationStateContext)
export const useNavDispatch = () => useContext(NavigationDispatchContext)