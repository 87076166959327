import { gql, useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../NavBar'
import Player from './player3Q'
import Container from '@material-ui/core/Container';
import { useAuthDispatch, useAuthState } from '../../context/auth'
import Guardien from './Guardian'
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';



const items = [{ link: "/stream", label: "Freiheitspalast (Bühne 1)" }, { link: "/exhibitors", label: "Aussteller" }, { link: "/logout", label: "Ausloggen" }]

const TITLE = 'Europakongress Finanzielle Freiheit 2021'

const ADD_QUESTION = gql`
  mutation addQuestion($from: String!, $content: String!) {
    addQuestion(from: $from ,content: $content) {
      content
    }
  }
`;


const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  box: {
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },

  video: {
    position: "relative",
    paddingBottom: "56.25%",
    paddingTop: "0px",
    height: "0",
    borderRadius: "10px",
    marginBottom: "-1px",
    marginTop: "50px",
  },
  videoFooter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    minHeight: "70px",
    backgroundColor: theme.palette.primary.main,
    marginBottom: "30px",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  image: {
    width: "100%"
  },



}));



export default function Home(props) {
  const classes = useStyles();
  const { user } = useAuthState()
  const [open, setOpen] = React.useState(false);

  const handleClickQuestion = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [variables, setVariables] = useState({
    anonym: false,
    content: '',
    author: user.alias

  })

  const [addQandA, { data, loading }] = useMutation(ADD_QUESTION);


  const handleSubmitQuestion = () => {

    if (variables.anonym) {
      addQandA({ variables: { roomId: 1, author: 'Anonym', content: variables.content } });

    } else {
      addQandA({ variables: { roomId: 1, author: user.alias, content: variables.content } });

    }
    setVariables({ ...variables, content: "" })
    setOpen(false);


  };



  useEffect(() => {
    const script = document.createElement('script')
    script.src = "https://go.arena.im/public/js/arenachatlib.js?p=die-kunst-dein-ding-zu-machen-digital-38947&e=0mxv"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script);


    }
  }, []);


  return (
    <>
      <Guardien project={"berner-de"} />
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <NavBar items={items} burger={true} right={"Freiheitspalast (Bühne 1)"} />


      <Container maxWidth="md" style={{ marginTop: "106px" }}>
        <div className={classes.box}>
          <div className={classes.video} id='vdiv'>  <Player project={"basic"} dataId={"37de9667-976f-11eb-b839-0cc47a188158"} /></div>
          <div className={classes.videoFooter}>
            <Button
              variant="contained"
              className={classes.btn}
              size="large"
              color="secondary"
              onClick={handleClickQuestion}
            >Frage ins Studio</Button>
          </div>

        </div>
        <div className="arena-embed-widget" data-publisher="die-kunst-dein-ding-zu-machen-digital-38947" data-type="social-wall" data-showtitle="false" data-lang="de" data-timeline="7tkj"></div>




        <Grid container style={{marginTop: "25px"}}
          direction="row"
          justify="center"
          alignItems="center" spacing={5}>
          <Grid item xs={12} sm={6} md={4}>
          <Link to={"https://www.klicktipp.com/"}>   
            <Paper className={classes.paper} elevation={2}>
              <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/sponsoren/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Livestream-Seiten+-+Sponsoren+v001+-+KlickTipp.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
          <Link to={"https://www.digistore24.com/"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/sponsoren/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Livestream-Seiten+-+Sponsoren+v001+-+digistore24.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
          <Link to={"https://www.coachannel.com/"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/sponsoren/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Livestream-Seiten+-+Sponsoren+v001+-+coachannel.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
        </Grid>
        <Grid container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginTop: "20px" }} spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
          <Link to={"https://info.elementum.de/klartext/"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/sponsoren/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Livestream-Seiten+-+Sponsoren+v001+-+Elementum.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
          <Link to={"https://www.max-otte-fonds.de/"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/sponsoren/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Livestream-Seiten+-+Sponsoren+v001+-+Max+Otte+Fonds.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>

        </Grid>

      </Container>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Frage ins Studio</DialogTitle>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmitQuestion()

          }}
        >
          <DialogContent>
            <DialogContentText>
              <Typography variant="body1">  Du kannst hier Deine Frage einreichen. </Typography>
            </DialogContentText>
            <TextField
              margin="dense"
              id="alias"
              defaultValue={variables.author}
              type="text"
              fullWidth
              variant="outlined"
              color="secondary"
              placeholder="Ihr Name"
              inputProps={{ maxLength: 20 }}
              required
              disabled={variables.anonym}

              onChange={e => setVariables({ ...variables, author: e.target.value })}

            />
            <TextField
              margin="dense"
              id="question"
              label="Ihre Antwort..."
              type="text"
              fullWidth
              multiline
              rows={6}
              variant="outlined"
              color="secondary"
              value={variables.content}
              onChange={e => setVariables({ ...variables, content: e.target.value })}
              inputProps={{ maxLength: 500 }}
              required
            />


            <FormControlLabel
              control={
                <Checkbox
                  checked={variables.anonym}
                  inputProps={{ 'aria-label': 'anonym' }}
                  onChange={e => setVariables({ ...variables, anonym: e.target.checked })}

                />}
              label="Anonym" />


          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Abbrechen
          </Button>
            <Button type="submit" color="primary">
              {(loading) ? <CircularProgress size={20} color="primary" /> : "Einreichen"}

            </Button>
          </DialogActions>
        </form>
      </Dialog>



    </ >
  );
}

