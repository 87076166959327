import { gql, useMutation } from '@apollo/client'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import NavBar from '../NavBar'
import Player from './player3Q'
import Container from '@material-ui/core/Container';
import { useAuthDispatch, useAuthState } from '../../context/auth'
import Guardien from './Guardian'
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link, useHistory } from 'react-router-dom';



const items = [{ link: "/stream", label: "Freiheitspalast (Bühne 1)" },{ link: "/stream2", label: "Visionstempel (Bühne 2) " }, { link: "/logout", label: "Ausloggen" }]

const TITLE = 'Europakongress Finanzielle Freiheit 2021'

const ADD_QUESTION = gql`
  mutation addQuestion($from: String!, $content: String!) {
    addQuestion(from: $from ,content: $content) {
      content
    }
  }
`;


const useStyles = makeStyles((theme) => ({
  dialogActions: {
    color: 'primary',
  },

  titleImage: {
    marginTop: "56px",
    position: "relative",
    width: "100%",
    height: "auto",
    [theme.breakpoints.up('sm')]: {
      marginTop: "64px",
    },
  },
  box: {
    boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  },

  video: {
    position: "relative",
    paddingBottom: "56.25%",
    paddingTop: "0px",
    height: "0",
    borderRadius: "10px",
    marginBottom: "-1px",
    marginTop: "50px",
  },
  videoFooter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    minHeight: "70px",
    backgroundColor: theme.palette.primary.main,
    marginBottom: "30px",
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  image: {
    width: "100%"
  },



}));



export default function Home(props) {
  const classes = useStyles();
  const { user } = useAuthState()
  const [open, setOpen] = React.useState(false);

  const handleClickQuestion = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [variables, setVariables] = useState({
    anonym: false,
    content: '',
    author: user.alias

  })

  const [addQandA, { data, loading }] = useMutation(ADD_QUESTION);


  const handleSubmitQuestion = () => {

    if (variables.anonym) {
      addQandA({ variables: { roomId: 1, author: 'Anonym', content: variables.content } });

    } else {
      addQandA({ variables: { roomId: 1, author: user.alias, content: variables.content } });

    }
    setVariables({ ...variables, content: "" })
    setOpen(false);


  };



  useEffect(() => {
    const script = document.createElement('script')
    script.src = "https://go.arena.im/public/js/arenachatlib.js?p=die-kunst-dein-ding-zu-machen-digital-38947&e=0mxv"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script);


    }
  }, []);


  return (
    <>
      <Guardien project={"berner-de"} />
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <NavBar items={items} burger={true} right={"Freiheitspalast (Bühne 1)"} />


      <Container maxWidth="md" style={{ marginTop: "106px" }}>
     



        <Grid container style={{marginTop: "25px"}}
          direction="row"
          justify="center"
          alignItems="center" spacing={5}>
          <Grid item xs={12} sm={6} md={6}>
          <Link to={"/exhibitors/1"}>   
            <Paper className={classes.paper} elevation={2}>
              <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+Elementum.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/2"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+Autima.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/3"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+Eva+Abert.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/4"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+Florian+G%E2%94%9C%E2%95%9Dnther.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/5"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+J%E2%94%9C%E2%95%9Drgen+Wechsler.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/6"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+KlickTipp.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/7"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+Max+Otte.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/8"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+Paul+Misar.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/9"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+Peter+H%E2%94%9C%D0%B4rtling.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/10"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+TWI-2.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/11"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+TWI.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
          <Link to={"/exhibitors/12"}>   

            <Paper className={classes.paper} elevation={2}>          <img src={"https://s3.eu-central-1.amazonaws.com/open.ressources/public/company/tw/assets/exhibitors/2104+Thorsten+Wittmann+-+Europakongress+Finanzielle+Freiheit+-+Plattform+-+Aussteller+-+%E2%94%9C%D0%ACbersichts-Seite+-+Logos+v001+-+coachannel.jpg"} alt="" className={classes.image} />
            </Paper>
    </Link>
          </Grid>
        </Grid>
    

      </Container>

     


    </ >
  );
}

